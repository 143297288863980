import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MpxLabel = _resolveComponent("MpxLabel")!
  const _component_MpxTextField = _resolveComponent("MpxTextField")!
  const _component_MpxButton = _resolveComponent("MpxButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[18] || (_cache[18] = _createElementVNode("div", { class: "page-title" }, [
      _createElementVNode("h1", null, "HMAC Validator")
    ], -1)),
    _createElementVNode("form", {
      class: "form-container",
      onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"]))
    }, [
      _createVNode(_component_MpxLabel, { class: "label-container" }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode(" Client ID ")
        ])),
        _: 1
      }),
      _createVNode(_component_MpxTextField, {
        class: "input-container",
        id: "clientId",
        prefix: "mpx-",
        variant: "text-field",
        value: _ctx.hmac.clientId,
        onInput: _ctx.handleInput,
        placeholder: "Your application client ID"
      }, null, 8, ["value", "onInput"]),
      _createVNode(_component_MpxLabel, null, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode(" Client Secret ")
        ])),
        _: 1
      }),
      _createVNode(_component_MpxTextField, {
        class: "input-container",
        id: "clientSecret",
        prefix: "mpx-",
        variant: "text-field",
        value: _ctx.hmac.clientSecret,
        onInput: _ctx.handleInput,
        placeholder: "Your application client Secret"
      }, null, 8, ["value", "onInput"]),
      _createVNode(_component_MpxLabel, null, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createTextVNode(" HTTP Method ")
        ])),
        _: 1
      }),
      _withDirectives(_createElementVNode("select", {
        class: "form-control input-container",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.hmac.httpMethod) = $event))
      }, _cache[5] || (_cache[5] = [
        _createElementVNode("option", { value: "get" }, "GET", -1),
        _createElementVNode("option", { value: "post" }, "POST", -1),
        _createElementVNode("option", { value: "patch" }, "PATCH", -1),
        _createElementVNode("option", { value: "put" }, "PUT", -1)
      ]), 512), [
        [_vModelSelect, _ctx.hmac.httpMethod]
      ]),
      _createVNode(_component_MpxLabel, null, {
        default: _withCtx(() => _cache[6] || (_cache[6] = [
          _createTextVNode(" Full API URL ")
        ])),
        _: 1
      }),
      _createVNode(_component_MpxTextField, {
        class: "input-container",
        error: _ctx.getError('url'),
        id: "url",
        "data-type": "url",
        "data-validate": "true",
        prefix: "mpx-",
        value: _ctx.hmac.url,
        variant: "text-field",
        placeholder: "https://api.mekari.com/v2/talenta/v2/employee",
        onInput: _ctx.handleInput
      }, null, 8, ["error", "value", "onInput"]),
      (_ctx.getError('url'))
        ? (_openBlock(), _createBlock(_component_MpxLabel, { key: 0 }, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [
              _createTextVNode(" URL is invalid ")
            ])),
            _: 1
          }))
        : _createCommentVNode("", true),
      _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1)),
      _createVNode(_component_MpxLabel, null, {
        default: _withCtx(() => _cache[8] || (_cache[8] = [
          _createTextVNode(" Date Header Value ")
        ])),
        _: 1
      }),
      _createVNode(_component_MpxTextField, {
        class: "input-container",
        error: _ctx.getError('date'),
        id: "date",
        "data-type": "date",
        "data-validate": "true",
        prefix: "mpx-",
        value: _ctx.hmac.date,
        variant: "text-field",
        placeholder: "Wed, 10 Nov 2021 07:24:29 GMT",
        onInput: _ctx.handleInput
      }, null, 8, ["error", "value", "onInput"]),
      (_ctx.getError('date'))
        ? (_openBlock(), _createBlock(_component_MpxLabel, { key: 1 }, {
            default: _withCtx(() => _cache[9] || (_cache[9] = [
              _createTextVNode(" Invalid date format. Format must follow RFC7231. Example: Wed, 10 Nov 2021 07:24:29 GMT ")
            ])),
            _: 1
          }))
        : _createCommentVNode("", true),
      _cache[14] || (_cache[14] = _createElementVNode("br", null, null, -1)),
      _createVNode(_component_MpxButton, {
        type: "submit",
        label: "Submit"
      }),
      _cache[15] || (_cache[15] = _createElementVNode("br", null, null, -1)),
      _cache[16] || (_cache[16] = _createElementVNode("br", null, null, -1)),
      (_ctx.headers !== '')
        ? (_openBlock(), _createBlock(_component_MpxLabel, { key: 2 }, {
            default: _withCtx(() => [
              _cache[10] || (_cache[10] = _createTextVNode(" Correct Header HMAC: ")),
              _cache[11] || (_cache[11] = _createElementVNode("br", null, null, -1)),
              _createElementVNode("p", null, "Authorization : " + _toDisplayString(_ctx.headers.headers.Authorization), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _cache[17] || (_cache[17] = _createElementVNode("br", null, null, -1)),
      (_ctx.headers !== '')
        ? (_openBlock(), _createBlock(_component_MpxLabel, { key: 3 }, {
            default: _withCtx(() => _cache[12] || (_cache[12] = [
              _createTextVNode(" cURL example ")
            ])),
            _: 1
          }))
        : _createCommentVNode("", true)
    ], 32),
    (_ctx.headers !== '')
      ? (_openBlock(), _createElementBlock("pre", _hoisted_1, "        curl --request " + _toDisplayString(_ctx.hmac.httpMethod.toUpperCase()) + " '" + _toDisplayString(_ctx.hmac.url) + "' \\\n        --header 'Authorization: " + _toDisplayString(_ctx.headers.headers.Authorization) + "' \\\n        --header 'Date: " + _toDisplayString(_ctx.hmac.date) + "' \\\n        --header 'Content-Type: " + _toDisplayString(_ctx.headers.headers["Content-Type"]) + "' \\\n        --data-raw ''\n    ", 1))
      : _createCommentVNode("", true)
  ], 64))
}